import { useEffect, useState } from "react";

import { ReduxAuth } from "./types";

declare global {
  interface Window {
    getAuthData?: () => ReduxAuth;
  }
}

export const useReduxAuth = (): ReduxAuth | undefined => {
  const [isLoading, setIsLoading] = useState<ReduxAuth["isLoading"]>(true);
  const [isAuthenticated, setIsAuthenticated] =
    useState<ReduxAuth["isAuthenticated"]>(false);
  const [identity, setIdentity] = useState<ReduxAuth["identity"]>(null);

  useEffect(() => {
    const getAuthData = (retry: number) => {
      if (isLoading) {
        const authData = window.getAuthData ? window.getAuthData() : undefined;

        if (authData?.isAuthenticated) {
          setIsLoading(authData.isLoading);
          setIsAuthenticated(authData.isAuthenticated);
          setIdentity(authData.identity);
        } else if (retry < 10) {
          setTimeout(() => getAuthData(++retry), retry * 100);
        }
      }
    };

    getAuthData(0);
  }, []);

  return {
    isLoading,
    isAuthenticated,
    identity,
  };
};
