import { ThemeConfig } from "antd";
import { merge } from "lodash-es";

import { themeConfig as sharedThemeConfig } from "../shared";

const PRIMARY_COLOR = "#F16622";
const PRIMARY_HOVER_COLOR = "#D8500E";
const SUCCESS_COLOR = "#00861D";
const TEXT_COLOR = "#1A1C21";
const INFO_COLOR = "#24242D";
const TRACE_COLOR = "#A9A9A9";

export const esTheme: ThemeConfig = {
  token: {
    borderRadius: 4,
    colorPrimary: PRIMARY_COLOR,
    colorTextSecondary: INFO_COLOR,
    fontFamily: "trade-gothic-next, arial, sans-serif",
    fontFamilyCode: "trade-gothic-next, arial, sans-serif",
  },
  components: {
    Button: {
      colorPrimaryHover: PRIMARY_HOVER_COLOR,
      colorPrimary: PRIMARY_COLOR,
      colorLink: PRIMARY_COLOR,
      colorLinkHover: PRIMARY_HOVER_COLOR,
      linkDecoration: "underline",
    },
    Input: {
      colorBorder: TRACE_COLOR,
      borderRadius: 4,
      hoverBorderColor: TEXT_COLOR,
      activeBorderColor: TEXT_COLOR,
    },
    Select: {
      colorBorder: TRACE_COLOR,
      borderRadius: 4,
      optionPadding: "8px 16px",
    },
    InputNumber: {
      borderRadius: 4,
      addonBg: INFO_COLOR,
      colorBorder: TRACE_COLOR,
      hoverBorderColor: TEXT_COLOR,
      activeBorderColor: TEXT_COLOR,
    },
    Typography: {
      fontSizeHeading3: 20,
      lineHeightHeading3: 1.3,
    },
    Steps: {
      colorSplit: TRACE_COLOR,
      controlItemBgActive: SUCCESS_COLOR,
      colorText: TEXT_COLOR,
      colorPrimary: TEXT_COLOR,
      fontSize: 16,
    },
    Slider: {
      handleColor: INFO_COLOR,
      trackBg: INFO_COLOR,
      trackHoverBg: INFO_COLOR,
      handleActiveColor: INFO_COLOR,
    },
    Progress: {},
  },
};

export const themeConfig: ThemeConfig = merge({}, sharedThemeConfig, esTheme);
